@import "../../globalStylesVariebles.scss";

div#message {
    border: 5px solid black;
    margin: 3% 10% 6% 10%;
    padding: 2% 10%;
    border-radius: 1rem 1rem;
    box-shadow: 1px 1px 50px $background-color;
    background-color: #f2eecb;
    font-size: 1rem;
    font-weight: 900;
    h1,
    h2 {
        font-size: 1.5rem;
    }
    h2 {
        color: darken($background-color, 25%);
    }

    h2,
    h3 {
        margin-top: 2%;
    }
    ul {
        li {
            list-style: none;
        }
    }
    button {
        width: 11rem;
        padding: 0.2rem;
        font-size: 0.8rem;
        font-weight: 900;
        font-style: italic;
        background-color: $background-color;
        border: 2px solid black;
        border-radius: 5px 5px;

        &:hover {
            box-shadow: 1px 1px 10px $background-color;
        }
    }
    @media (max-width: 800px) {
        h1,
        h2 {
            font-size: 1.2rem;
        }

        h3 {
            font-size: 1rem;
        }

        div,
        pre {

            font-size: 0.7rem;

        }
    }
}
