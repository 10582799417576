#gallery {
    h1,
    h3 {
        margin-top: 5%;
        padding-bottom: 3%;
        text-align: center;
        font-weight: 900;
    }
    div.row {
        border-bottom: 5px solid black;
        padding-bottom: 5%;
    }
    @media (max-width: 620px) {
        div.row {
            margin: 2% 8%;
        }
    }
    a {
        text-decoration: none;
    }
    img {
        width: 20rem;
        height: 10rem;
        margin: 4% 8%;
        &:hover {
            box-shadow: 1px 1px 10px gray;
        }
    }
}
