/* SCSS HEX */
$A: #e6b027;
$B: #f03629;
$C: #7030d9;
$D: #29d8f0;
$E: #8be66a;

$background-color: $E;
$shadow: $C;
$footer: $D;

.bold{
    font-weight: 900;
}
