@import "../../globalStylesVariebles.scss";

#footer {
    padding: 1%;
    margin-top: 5%;
    text-align: center;
    background-color: $footer;
    font-size: 1.2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}
