@import "../../globalStylesVariebles.scss";

#books {
    font-size: 1.3rem;
    font-weight: 600;

    h1 {
        text-align: center;
        font-weight: 800;
    }

    label {
        margin: 2% 1% 2% 8%;
    }

    input {
        outline: none;
        width: 15rem;
        border-style: none;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
    }
    @media (max-width: 556px) {
        input {
            margin: 3% 1% 5% 8%;
        }
    }
    div article {
        margin: 0 10%;
        border-bottom: 5px solid black;
        h3 {
            font-weight: bolder;
            text-align: center;
        }
        &:first-child {
            border-top: 5px solid black;
        }
    }
}
